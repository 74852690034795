import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import styled, { css, useTheme } from "styled-components"
import { graphql } from "gatsby"

import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import GradientText from "@components/common/gradient-text"
import Image from "@components/image"
import { FeaturedResource } from "@components/resources/featured-resource"
import { ResourcesFeaturedCategory } from "@components/resources/resources-featured-category"
import { FeaturedKpi } from "@components/resources/featured-kpi"

import Gradient from "@images/pm-standard-gradient.png"
import { processImages } from "@utils/process-images"

import media from "@styles/media"
import theme from "@styles/theme"

const GlassyDiv = styled(Div)`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: ${theme.shadow.glassy};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.59);
  ${media.sm`
    ${props =>
      props.reverse &&
      css`
        order: -1;
      `}
  `}
`

const ImageGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  min-width: 2500px;
  z-index: 0;
  height: auto;
`

const Resources = ({ data }) => {
  const resources = data.resources
  const articles = resources.featured_articles
  const data_stack = resources.featured_data_stack
  const dashboards = resources.featured_dashboard_examples
  const kpis = resources.featured_kpi_examples
  const images = processImages(data.images.edges)
  const dashboard_categories = resources.featured_dashboard_categories
  const kpi_categories = resources.featured_kpi_example_categories
  const hasArticle = articles.length > 0
  const { color } = useTheme()

  return (
    <Layout
      title={`Resources`}
      description={`Articles, best-practices, ideas, examples, and webinars to take you from design to dashboard.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex
          background={color.indigo700}
          position="relative"
          overflow="hidden"
        >
          <ImageGradient src={Gradient} />
          <Container>
            <Grid
              columns="2fr 3fr"
              columnsMd="1fr 1fr"
              columnsSm="1fr"
              gap="4rem"
              gapSm="2rem"
              margin="8rem 0 4rem"
              marginSm="8rem 0 2rem"
            >
              <Div>
                <Paragraph
                  color="white"
                  fontSize="1rem"
                  fontWeight="700"
                  margin="0 0 1rem"
                >
                  Articles
                </Paragraph>
                {hasArticle && (
                  <>
                    <Heading fontSize="2rem" color="white" margin="0 0 1rem">
                      <Anchor
                        color="white"
                        link={`articles/${articles[0].slug}`}
                        arrow
                      >
                        {articles[0].seo.title}
                      </Anchor>
                    </Heading>
                    <Paragraph lineHeight="1.6" color="white" margin="0 0 2rem">
                      {articles[0].seo.description}
                    </Paragraph>
                  </>
                )}
              </Div>
              {hasArticle && (
                <GlassyDiv reverse alignSelf="center">
                  <Image eager radius={1} file={articles[0].image} />
                </GlassyDiv>
              )}
            </Grid>
            <Grid
              gap="2rem"
              columns={"repeat(4, 1fr)"}
              columnsSm="repeat(2, 1fr)"
              margin="0 0 4rem"
            >
              {articles.map((article, i) => {
                return (
                  i > 0 &&
                  i < 4 && (
                    <Div key={`featured-article-${i}`}>
                      <hr />
                      <Paragraph
                        lineHeight="1.5"
                        fontWeight={"700"}
                        fontSize="1.2rem"
                      >
                        <Anchor color="white" link={`articles/${article.slug}`}>
                          {article.seo.title}
                        </Anchor>
                      </Paragraph>
                    </Div>
                  )
                )
              })}
              <Div>
                <hr />
                <Paragraph
                  lineHeight="1.5"
                  fontWeight={"700"}
                  fontSize="1.2rem"
                  color="white"
                >
                  <Anchor link={`articles`} arrow color="white">
                    View all
                  </Anchor>
                </Paragraph>
              </Div>
            </Grid>
          </Container>
        </Flex>
        <Flex gap="6rem">
          <Div background={color.indigo100}>
            <Grid
              container
              gap="0.5rem 2rem"
              columns={"repeat(4, 1fr)"}
              columnsSm="repeat(2, 1fr)"
              margin="2rem auto 3rem"
            >
              <Paragraph fontWeight={700} style={{ gridColumn: "1/-1" }}>
                Data Stack Articles
              </Paragraph>
              {data_stack.map((article, i) => {
                return (
                  i > 0 &&
                  i < 4 && (
                    <Div key={`featured-article-${i}`}>
                      <hr style={{ background: color.indigo600 }} />
                      <Paragraph
                        lineHeight="1.5"
                        fontWeight={"700"}
                        fontSize="1.1rem"
                      >
                        <Anchor link={`data-stack/${article.slug}`}>
                          {article.seo.title}
                        </Anchor>
                      </Paragraph>
                    </Div>
                  )
                )
              })}
              <Div>
                <Paragraph
                  lineHeight="1.5"
                  fontWeight={"700"}
                  fontSize="1.1rem"
                >
                  <hr style={{ background: color.indigo600 }} />
                  <Anchor link={`data-stack`} arrow>
                    View all data stack articles
                  </Anchor>
                </Paragraph>
              </Div>
            </Grid>
          </Div>
          <Div>
            <Container>
              <Grid
                gap="2rem"
                columns="3fr 2fr"
                columnsMd="1fr 1fr"
                columnsSm="1fr"
                margin="0 0 2rem"
              >
                {dashboards.map((dashboard, i) => {
                  return (
                    <FeaturedResource
                      key={`featured-resource-${i}`}
                      name={dashboard.name}
                      summary={dashboard.summary}
                      image={dashboard.image}
                      slug={`${dashboard.category.slug}/${dashboard.slug}`}
                      dashboard
                      bottom={i % 2 === 0}
                    />
                  )
                })}
              </Grid>
              <Grid
                gap="2rem"
                columns="repeat(3, 1fr)"
                columnsMd="1fr 1fr"
                columnsSm="1fr"
              >
                {kpis.map((kpi, i) => {
                  return (
                    <FeaturedKpi
                      key={`kpi-examples-${i}`}
                      name={kpi.title}
                      summary={kpi.summary}
                      image={kpi.image}
                      slug={`${kpi.category.slug}/${kpi.slug}`}
                    />
                  )
                })}
              </Grid>
            </Container>
          </Div>
          <Div background={color.indigo700} overflow="hidden">
            <Container>
              <Div position="relative">
                <ImageGradient src={Gradient} />
                <Grid
                  columns="1fr 1fr"
                  columnsMd="1fr"
                  gap="4rem"
                  gapSm="2rem"
                  padding="6rem 0"
                  paddingSm="2rem 0"
                >
                  <GlassyDiv
                    height="530px"
                    overflow="hidden"
                    padding="2rem 2rem 0 2rem"
                  >
                    <Flex>
                      <Heading
                        as="h2"
                        fontSize="2rem"
                        margin="0 0 1rem"
                        color="white"
                      >
                        <Anchor
                          link="dashboard-examples"
                          color="white"
                          hover={theme.color.greyWhite}
                          arrow
                        >
                          KPI Dashboards
                        </Anchor>
                      </Heading>
                      <Paragraph
                        lineHeight="1.6"
                        fontSize="1.1rem"
                        color="white"
                        margin="0 0 2rem"
                      >
                        Over 60 business dashboards for Executives, Sales,
                        Marketing, Social Media, and more. Check out this
                        gallery to get inspired in building your own today!
                      </Paragraph>
                      <Image
                        file={images["9ba51ce0-089f-471a-9764-6444e9ed344f"]}
                      />
                    </Flex>
                  </GlassyDiv>
                  <GlassyDiv height="530px" overflow="hidden" padding="2rem">
                    <Flex>
                      <Heading
                        as="h2"
                        fontSize="2rem"
                        margin="0 0 1rem"
                        color="white"
                      >
                        <Anchor
                          link="kpi-examples"
                          color="white"
                          hover={theme.color.greyWhite}
                          arrow
                        >
                          KPI Examples
                        </Anchor>
                      </Heading>
                      <Paragraph
                        margin="0 0 2rem"
                        fontSize="1.1rem"
                        lineHeight="1.6"
                        color="white"
                      >
                        Visual KPI examples and templates for key departments
                        such as Sales, Marketing, Accounting, Supply Chain, Call
                        Centers and more.
                      </Paragraph>
                      <Image
                        file={images["c1bf976f-4b09-4607-b905-dac4a77451e4"]}
                      />
                    </Flex>
                  </GlassyDiv>
                </Grid>
              </Div>
            </Container>
          </Div>
          <Div>
            <Container>
              <Heading
                maxWidth="600px"
                center
                as="h2"
                margin="0 auto"
                padding="0 0 2rem"
              >
                <GradientText variant="powermetrics">
                  Browse Dashboards & KPI Examples by Category
                </GradientText>
              </Heading>
              <Paragraph center margin="0 0 4rem">
                View all <Anchor link="kpi-examples">KPI Examples</Anchor> or{" "}
                <Anchor link="dashboard-examples">Dashboard Examples</Anchor>
              </Paragraph>
              <Flex gap="4rem" margin="0 0 2rem">
                <FeaturedCategories
                  dashboards={dashboard_categories}
                  kpis={kpi_categories}
                />
              </Flex>
            </Container>
          </Div>
        </Flex>
      </Container>
    </Layout>
  )
}

const FeaturedCategories = ({ dashboards, kpis }) => {
  const categories = []

  for (let i = 0; i < dashboards.length && i < kpis.length; i++) {
    categories.push(
      <ResourcesFeaturedCategory
        name={dashboards[i].name}
        subtitle={dashboards[i].subtitle}
        slug={dashboards[i].slug}
        examples={dashboards[i].dashboard_examples}
        dashboard
        key={`dashboard-category-${i}`}
      />
    )

    categories.push(
      <ResourcesFeaturedCategory
        name={kpis[i].name}
        subtitle={kpis[i].subtitle}
        slug={kpis[i].slug}
        examples={kpis[i].kpi_examples}
        key={`kpi-category-${i}`}
      />
    )
  }

  return categories
}

Resources.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ResourcesQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "9ba51ce0-089f-471a-9764-6444e9ed344f"
            "c1bf976f-4b09-4607-b905-dac4a77451e4"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
    resources {
      featured_articles {
        image {
          cdn
          placeholder
          title
        }
        seo {
          title
          description
        }
        slug
      }
      featured_data_stack {
        image {
          cdn
          placeholder
          title
        }
        seo {
          title
          description
        }
        slug
      }
      featured_dashboard_examples {
        summary
        name
        slug
        category {
          slug
        }
        image {
          cdn
          title
          placeholder
        }
      }
      featured_kpi_examples {
        summary
        title
        slug
        category {
          slug
        }
        image {
          cdn
          title
          placeholder
        }
      }
      featured_dashboard_categories {
        dashboard_examples {
          slug
          image {
            cdn
            placeholder
            title
          }
          name
          subtitle
        }
        name
        subtitle
        slug
      }
      featured_kpi_example_categories {
        kpi_examples {
          h1
          image {
            cdn
            title
            placeholder
          }
          title
          slug
          subtitle
        }
        subtitle
        name
        slug
      }
    }
  }
`

export default Resources
