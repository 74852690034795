import React from "react"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import GradientText from "@components/common/gradient-text"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import theme from "@styles/theme"
import Flex from "@components/elements/flex"
import { useTheme } from "styled-components"

export const FeaturedKpi = ({ name, summary, slug, image }) => {
  const { color } = useTheme()

  return (
    <Div>
      <Flex
        background={color.indigo100}
        borderRadius="1rem"
        padding="2rem"
        height="300px"
        margin="0 0 2rem"
        justifyContent="center"
      >
        <Image
          radius={0.5}
          shadow
          file={image}
          showOverflow
          borderStyle={{
            boxShadow: theme.shadow.dramatic,
          }}
        />
      </Flex>
      <Paragraph fontSize="1rem" fontWeight="700" margin="0 0 0.5rem">
        <GradientText variant="powermetrics">KPI Example</GradientText>
      </Paragraph>
      <Heading as="h3" fontSize="1.4rem" margin="0 0 1.5rem">
        <Anchor
          color={theme.color.indigo500}
          hover={theme.color.indigo300}
          arrow
          link={`kpi-examples/${slug}`}
        >
          {name}
        </Anchor>
      </Heading>
      <Paragraph lineHeight="1.6" fontSize="1rem" margin="0 0 1rem">
        {summary}
      </Paragraph>
    </Div>
  )
}

FeaturedKpi.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}
